@use "../base/variables" as *;
@use "../../../node_modules/include-media/dist/include-media" as *;
@use "../utils/grid" as *;

@mixin phone {
    @include media("<phone") {
        @content;
    }
}

@mixin tablet {
    @include media(">phone", "<=tablet") {
        @content;
    }
}

@mixin desktop {
    @include media(">tablet", "<=desktop") {
        @content;
    }
}

@mixin large {
    @include media(">desktop") {
        @content;
    }
}

$transition-duration: 0.3s;

@mixin transition-base($properties...) {
    @if $properties {
        transition-property: $properties;
    } @else {
        transition-property: all;
    }
    transition-duration: $transition-duration;
}
