.profitContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.chooseCurrencyContainer {
    display: flex;
    align-items: center;
}

.selectContainer {
    margin-left: 15px;
}

.legalBoxContainer {
    margin: 0 10px;
}

.profitItem:not(:last-child) {
    margin-bottom: 7px;
}
