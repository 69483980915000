@use "../../../styles/fonts/fonts" as *;
@use "../../../styles/mixins/mixins" as *;

.formContainer {
    padding: 20px 40px 40px;

    @include tablet {
        padding: 20px;
    }

    @include phone {
        padding: 0px 20px;
    }
}

.formTitle {
    @include fontSection;
    margin-bottom: 40px;

    @include tablet {
        margin-bottom: 25px;
    }

    @include phone {
        margin-bottom: 15px;
    }
}
