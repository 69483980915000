@use "../../../../styles/fonts/fonts" as *;
@use "../../../../styles/mixins/mixins" as *;
@use "../../../../styles/base/variables" as *;

.stepItem {
    flex: 0 1 100%;
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 80px;
    background-color: $clr-grey-f4;

    &:not(:first-child) {
        margin-left: -40px;
    }

    & .stepNumber {
        color: $clr-black;
    }

    & .stepName {
        color: $clr-grey-3;
    }

    @include phone {
        height: 80px;
        padding-left: 20px;
    }
}

.stepItem--active {
    background-color: $clr-grey-46;
    border-color: $clr-grey-46;

    &:not(:last-child) + .arrow {
        z-index: 1;
        width: 0;
        height: 0;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;
        border-left: 40px solid $clr-grey-46;

        @include phone {
            border: none;
        }
    }

    & .stepNumber,
    & .stepName {
        color: $clr-white;
    }
}

.stepItem--passed {
    background-color: $clr-grey-46;
    border-color: $clr-grey-46;

    & .stepNumber,
    & .stepName {
        color: $clr-white;
    }
}

.stepNumber {
    @include fontSection;
    font-size: 2.4rem;
    margin-bottom: 5px;

    @include phone {
        font-size: 1.7rem;
    }
}

.stepName {
    @include fontBody;

    @include phone {
        font-size: 1.5rem;
    }
}

.mobileNext {
    margin: 0 10px 10px auto;
    align-self: flex-end;
    @include fontSecondary($clr-white);
    font-style: italic;
}
