@use "../../../styles/fonts/fonts" as *;
@use "../../../styles/mixins/mixins" as *;

.sectionContainer {
    margin-bottom: 30px;
}

.sectionTitle {
    @include fontTitle;
    font-size: 2rem;
    margin-bottom: 1rem;

    @include phone {
        font-size: 1.6rem;
    }
}
