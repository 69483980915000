@use "../../../styles/fonts/fonts" as *;
@use "../../../styles/mixins/mixins" as *;
@use "../../../styles/base/variables" as *;

.tableContainer {
    overflow-x: visible;
    padding: 2rem 1rem;

    @include tablet {
        padding: 1rem;
    }

    @include phone {
        padding: 1rem;
    }
}

.tableEl {
    border: none;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.12);
}

.greyFirst {
    .trEl {
        &__row {
            &:nth-child(odd) {
                background-color: $clr-grey-fa;
            }
        }
    }
}

.whiteFirst {
    .trEl {
        &__row {
            &:nth-child(even) {
                background-color: $clr-grey-fa;
            }
        }
    }
}

.colorEveryTwo {
    .trEl {
        &__row {
            &:nth-child(4n + 2),
            &:nth-child(4n + 3) {
                background-color: $clr-grey-fa;
            }
        }
    }
}

.trEl {
    &__row {
        @include fontBody;
        font-size: 1.4rem;
    }

    &__head {
        background-color: $clr-white;

        & .tdEl {
            padding: 2rem;

            @include phone {
                padding: 1rem;
            }
        }
    }
}

.tdEl,
.trEl {
    &__head {
        @include fontTitle;
        font-size: 1.4rem;
        font-weight: 700;
    }
}

.tdEl {
    padding: 2rem;

    @include tablet {
        padding: 1.4rem;
        font-size: 1.4rem;
    }

    @include phone {
        padding: 1rem;
        font-size: 1.4rem;
    }
}

.centered {
    text-align: center;
}
