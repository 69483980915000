@use "../../../../../styles/fonts/fonts" as *;
@use "../../../../../styles/mixins/mixins" as *;
@use "../../../../../styles/base/variables" as *;

.subTitle {
    @include fontTitle;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.collapseRowsContainer {
    @include fontTitle($clr-red);
    font-size: 1.8rem;
    cursor: pointer;
    margin: 10px;
}

.chartsContainer {
    text-align: center;
    & > div:first-child {
        width: 70%;
    }

    & > div:last-child {
        width: 30%;
    }

    & > div {
        display: inline-block;
    }

    @include phone {
        & > div:first-child {
            width: 100%;
        }

        & > div:last-child {
            width: 100%;
        }

        & > div {
            display: block;
        }
    }
}

.sectionContainer {
    margin-bottom: 30px;
}

.legalBoxContainer {
    margin: 0 10px;
}
