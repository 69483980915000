@use "../../../styles/base/variables" as *;

.riskContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.riskItem {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: $clr-grey-82;
    margin: 0 2px;

    &--active {
        background-color: $clr-red-dark;
    }
}
