@use "../../../../../styles/fonts/fonts" as *;
@use "../../../../../styles/mixins/mixins" as *;
@use "../../../../../styles/base/variables" as *;

.walletLabel {
    @include fontTitle;
    font-size: 1.8rem;

    @include phone {
        font-size: 1.4rem;
    }
}

.tableContainer {
    margin: 0;
}

.walletContainer {
    margin: 0 0 30px;
}
