@use "../styles/mixins/mixins" as *;

.mainContainer {
    width: 100%;
    padding: 0 120px;

    @include tablet {
        height: 100vh;
        padding: 0 30px;
    }

    @include phone {
        height: 100vh;
        padding: 0;
    }
}
