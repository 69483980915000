@use "../../../../../styles/fonts/fonts" as *;
@use "../../../../../styles/mixins/mixins" as *;

.sliderInputContainer {
    display: flex;
    align-items: center;
}

.sliderContainer {
    width: 100%;
    margin-right: 40px;

    @include tablet {
        margin: 25px 40px;
    }

    @include phone {
        margin: 25px 40px;
    }
}

.inputCurrency {
    @include fontSecondary;
    font-size: 1.6rem;
    margin-left: 15px;

    @include phone {
        display: none;
    }
}
