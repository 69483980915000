// Colors
$clr-white: #fff;
$clr-black: #282828;
$clr-grey-fa: #fafafa;
$clr-grey-f4: #f4f4f4;
$clr-grey-46: #464646;
$clr-grey-3: #333333;
$clr-grey-82: #828282;
$clr-pink: #f6ebeb;
$clr-red: #d41817;
$clr-red-dark: #c9001a;