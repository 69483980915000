@use "../../../styles/fonts/fonts" as *;
@use "../../../styles/base/variables" as *;

.sliderTooltip {
    position: absolute;
    left: 0;
    @include fontSliderInput($clr-red);
    visibility: visible;
    display: block;
}

:global {
    .rc-slider-handle:active {
        box-shadow: 0 0 5px $clr-red;
    }

    .rc-slider-dot {
        display: none;
    }

    .rc-slider-mark-text{
        width: fit-content !important;
    }
}
