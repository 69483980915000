.navigationsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0;
}

.btnOffset {
    margin-left: 20px;
}

.errorContainer{
    margin-right: auto;
}