@use "../../../styles/fonts/fonts" as *;
@use "../../../styles/mixins/mixins" as *;
@use "../../../styles/base/variables" as *;

.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 0 20px;

    @include phone {
        margin: 10px 0;
    }

    input {
        position: absolute;
        left: -9999em;
        opacity: 0;
        visibility: hidden;

        &:checked {
            + .customCheck {
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}

.customCheck {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: $clr-white;
    border: 3px solid $clr-red;
    position: relative;
    border-radius: 50%;

    &::before {
        content: "";
        width: 8px;
        height: 8px;
        background: $clr-red;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        opacity: 0;
        @include transition-base(opacity);
    }
}

.label {
    @include fontCheckRadio;
    padding-left: 20px;
}
