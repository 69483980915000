@use "../../../styles/fonts/fonts" as *;
@use "../../../styles/mixins/mixins" as *;
@use "../../../styles/base/variables" as *;

.input {
    height: 40px;
    min-width: 170px;
    text-align: center;
    padding: 15px 5px;
    border: 1px solid $clr-red;
    border-radius: 20px;
    @include fontInput($clr-red);

    &:focus-visible {
        border: 2px solid $clr-red;
        outline: none;
    }

    &:disabled {
        opacity: 0.5;
    }

    @include phone {
        padding: 1rem 0.5rem;
        min-width: 100px;
    }
}
