@use "../../../styles/base/variables" as *;
@use "../../../styles/mixins/mixins" as *;
@use "../../../styles/fonts/fonts" as *;

.button {
    @include fontBtn;
    text-transform: uppercase;
    padding: 1.6rem 3rem;
    border-radius: 40px;
    @include transition-base(background-color, border-color, color, text-decoration);

    @include phone {
        font-size: 1.2rem;
        padding: 1rem 1.5rem;
        line-height: 1.2rem;
    }

    &:hover {
        @include transition-base(background-color, border-color, color, text-decoration);
    }

    &--primary {
        color: $clr-white;
        background-color: #c9001a; // primary in styleguide
        // background-color: #d91918; // primary on site
        // background-color: $clr-red; // main red in styleguide
        border: 2px solid #c9001a;

        &:hover {
            background-color: #db172a; // primary hover in styleguide
            // background-color: #e72928; // hover on site
            border: 2px solid #db172a;
        }
    }

    &--secondary {
        background-color: $clr-white;
        border: 2px solid #c9001a;
        color: #c9001a;

        &:hover {
            background-color: #c9001a;
            color: $clr-white;
        }
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &--text {
        background-color: transparent;
        border: none;
        color: #c9001a;

        &:hover {
            text-decoration: underline;
        }
    }
}
