@use "../../../styles/fonts/fonts" as *;
@use "../../../styles/base/variables" as *;

.htmlContainer {
    @include fontSecondary;
}
.expandLabel {
    @include fontSecondary($clr-red);
    font-size: 1.3rem;
    font-weight: $fw-medium;
    margin: 5px 0;
    cursor: pointer;
}
.containerShow{
    position: relative;
}
.shortParagraph{
    position: absolute;
}