@use "../../../../../styles/fonts/fonts" as *;
@use "../../../../../styles/base/variables" as *;

.walletLabel {
    @include fontTitle;
    font-size: 1.4rem;
}

.proportionNumber {
    font-weight: $fw-bold;
}
