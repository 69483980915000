@use "../../../styles/mixins/mixins" as *;
@use "../../../styles/fonts/fonts" as *;
@use "../../../styles/base/variables" as *;

.hidden {
    visibility: hidden;
    opacity: 0;
    @include transition-base(visibility, opacity);
}

.error {
    @include transition-base(visibility, opacity);
    opacity: 1;
    position: absolute;
    visibility: visible;
    width: max-content;
    z-index: 3;
    background-color: $clr-red-dark;
    padding: 10px;
    border-radius: 6px;
    @include fontErrorTooltip($clr-white);
    text-transform: lowercase;
    text-align: center;

    &--top {
        left: 20px;
        right: 0;
        bottom: 150%;
    }

    &--left {
        top: 3px;
        right: 110%;
    }

    &--right {
        top: 3px;
        left: 110%;
    }

    &--bottom {
        top: 150%;
        left: 20px;
        right: 0;
    }
}

.error::after {
    content: "";
    position: absolute;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
}

.error {
    &--top::after {
        top: 100%;
        left: 20px;
        border-color: $clr-red-dark transparent transparent transparent;
    }

    &--left::after {
        top: 50%;
        left: 104%;
        border-color: transparent transparent transparent $clr-red-dark;
    }

    &--right::after {
        top: 50%;
        right: 100%;
        border-color: transparent $clr-red-dark transparent transparent;
    }

    &--bottom::after {
        left: 20px;
        bottom: 100%;
        border-color: transparent transparent $clr-red-dark transparent;
    }
}
