@use '../../../styles/fonts/fonts' as *;
@use '../../../styles/mixins/mixins' as *;

.formContainer {
    margin: 30px 34px;

    @include tablet {
        margin: 30px 15px;
    }

    @include phone {
        margin: 30px 0;
    }
}

.calcHeader {
    @include fontHeader;

    @include phone {
        margin: 0 20px;
        font-size: 2.5rem;
    }
}
