@use "../base/variables" as *;
@use "../mixins/mixins" as *;

//FONT WEIGHTS (fw)
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

//FONT STYLES (fs)
$fs-normal: normal;
$fs-italic: italic;

@font-face {
    font-family: "Sofia Pro";
    src: url("../../assets/fonts/SofiaPro/SofiaPro-Light.otf") format("opentype");
    font-weight: $fw-light;
    font-style: normal;
}

@font-face {
    font-family: "Sofia Pro";
    src: url("../../assets/fonts/SofiaPro/SofiaPro-Regular.otf") format("opentype");
    font-weight: $fw-regular;
    font-style: normal;
}

@font-face {
    font-family: "Sofia Pro";
    src: url("../../assets/fonts/SofiaPro/SofiaPro-Medium.otf") format("opentype");
    font-weight: $fw-medium;
    font-style: normal;
}

@font-face {
    font-family: "Sofia Pro";
    src: url("../../assets/fonts/SofiaPro/SofiaPro-Bold.otf") format("opentype");
    font-weight: $fw-bold;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../../assets/fonts/Raleway/static/Raleway-Light.ttf") format("opentype");
    font-weight: $fw-light;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../../assets/fonts/Raleway/static/Raleway-Regular.ttf") format("opentype");
    font-weight: $fw-regular;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../../assets/fonts/Raleway/static/Raleway-Medium.ttf") format("opentype");
    font-weight: $fw-medium;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("../../assets/fonts/Raleway/static/Raleway-Bold.ttf") format("opentype");
    font-weight: $fw-bold;
    font-style: normal;
}

@mixin font($size, $weight: normal, $color: $clr-black, $style: normal, $font: "Sofia Pro") {
    font-family: $font;
    font-size: $size;
    font-weight: $weight;
    font-style: $style;
    color: $color;
}

@mixin fontHeader($color: $clr-black) {
    @include font(5rem, $fw-regular, $color);
    line-height: 5rem;

    @include tablet {
        font-size: 3.4rem;
        line-height: 3.4rem;
    }

    @include phone {
        font-size: 2.8rem;
        line-height: 2.8rem;
    }
}

@mixin fontCalculatorHeader($color: $clr-black) {
    @include font(3.4rem, $fw-light, $color);
    line-height: 3.4rem;

    @include tablet {
        font-size: 2.8rem;
        line-height: 2.8rem;
    }

    @include phone {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
}

@mixin fontBodyRaleway($color: $clr-black) {
    @include font(1.8rem, $fw-light, $color, normal, "Raleway");
    line-height: 1.8rem;

    @include tablet {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }

    @include phone {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
}

@mixin fontBody($color: $clr-black) {
    @include font(1.8rem, $fw-light, $color, normal);
    line-height: 1.8rem;

    @include tablet {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }

    @include phone {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
}

@mixin fontSection($color: $clr-black) {
    @include font(2.8rem, $fw-regular, $color);
    line-height: 2.8rem;

    @include tablet {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }

    @include phone {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
}

@mixin fontTitle($color: $clr-black) {
    @include font(1.6rem, $fw-medium, $color);
    line-height: 1.6rem;
}

@mixin fontSecondary($color: $clr-black) {
    @include font(1.2rem, $fw-light, $color);
    line-height: 1.2rem;
}

@mixin fontBtn($color: $clr-black) {
    @include font(1.6rem, $fw-medium, $color, normal, "Raleway");
    line-height: 1.6rem;
}

@mixin fontCheckRadio($color: $clr-black) {
    @include font(1.4rem, $fw-regular, $color, normal, "Raleway");
    line-height: 1.4rem;
}

@mixin fontInput($color: $clr-black) {
    @include font(1.8rem, $fw-regular, $color);
    line-height: 1.8rem;
}

@mixin fontSliderInput($color: $clr-black) {
    @include font(1.4rem, $fw-bold, $color);
    line-height: 1.4rem;
}

@mixin fontErrorTooltip($color: $clr-black) {
    @include font(1.4rem, $fw-medium, $color);
    line-height: 1.4rem;
}
