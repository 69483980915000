@use "../../../../../styles/fonts/fonts" as *;

.tableContainer {
    padding: 4rem 1rem;
}

.amountContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.currency {
    @include fontSecondary;
    font-size: 1.6rem;
    margin-left: 15px;
}

.feeRow {
    padding: 0 0 1rem 0;
}

.calcFeeBtn {
    text-transform: none;
}
