*,
::after,
::before {
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    margin: 0;
    font-family: "Sofia Pro", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    font-weight: normal;
    cursor: default;
}

button {
    -webkit-appearance: button;
    font: inherit;
    cursor: pointer;
    overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

a:focus {
    outline: thin dotted;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
}

form {
    margin: 0;
}

b, strong {
    font-weight: bold;
}
input[type=text]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}