.steps {
    padding: 30px 0;
}

.stepsList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

}