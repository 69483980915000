@use "../../../../../styles/mixins/mixins" as *;

.tableContainer {
    margin-top: 50px;

    @include phone {
        margin-top: 20px;
    }
}

.selectContainer {
    max-width: 300px;
}

.sliderInputContainer {
    display: flex;
    align-items: center;
}

.sliderContainer {
    min-width: 300px;
    margin-right: 40px;

    @include tablet {
        min-width: unset;
        width: 100%;
    }

    @include phone {
        min-width: unset;
        width: 100%;
    }
}

.mobileSliderContainer {
    display: flex;
    margin: 30px 30px 40px;
    width: 100%;
}
